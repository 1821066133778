<template>
  <div v-if="!shouldHideContent" class="p-8 mt-10">
    <div class="flex flex-row items-end mb-10">
      <div class="w-2/3">
        <div class="flex flex-row items-center justify-between header-title-height">
          <h1 class="s-view-darker-title ml-8 mb-2">All Appointments</h1>
        </div>
        <h5>Find the claim you are searching for...</h5>
        <div class="relative flex items-center w-2/3">
          <input
            data-test-id="dashboard-search-input"
            type="text"
            v-model="searchTerm"
            placeholder="Search table columns like e.g. Patient, MRN, Insurer, ..."
            class="search-input-field_"
            @keyup.enter="searchAppointment(searchTerm)"
          />
          <i v-if="searchTerm" class="fas fa-times clear-search-icon" @click="clearSearch"></i>
          <div class="search-icon-wrapper search-icon" @click="searchAppointment(searchTerm)">
            <i
              data-test-id="search-magnifying-glass"
              class="fas fa-search search-icon"
              @click="searchAppointment(searchTerm)"
            ></i>
          </div>
        </div>
      </div>

      <div class="flex justify-end w-1/3">
        <!-- Function currently unavailable -->
        <!-- <button
          type="button"
          data-test-id="upload-patient-data-btn"
          class="flex items-center bg-medosync-blue px-6 py-3 rounded-full"
          @click="openUploadModal"
        >
          <i class="fa-solid fa-upload fa-xl pr-3" />
          <span data-test-id="upload-patient-data-btn" class="block">Upload patient data</span>
        </button> -->
      </div>
    </div>

    <DashboardPatient
      :id="'admission-table'"
      :headers="headers"
      :data="patients"
      :optionNoData="dataPropos"
      :optionSearch="false"
      :optionAddClick="true"
      :searchTerm="searchTerm"
      :datePickerdata="datePickerdata"
      :datePickerdataAdmission="datePickerdataDashboardAdmission"
      :datePickerdataDischarge="datePickerdataDashboardDischarge"
      :summary="summary"
      :dataSummary="dataSummary"
      :dataSummaryAdmission="dataSummaryAdmission"
      :dataSummaryDischarge="dataSummaryDischarge"
      ,
      :refreshIcon="refreshIcon"
      @filteredItens="filteredItens"
      @filteredItensDatePicker="filteredItensDatePicker"
      @filteredItensDatePickerAdmission="filteredItensDatePickerAdmission"
      @filteredItensDatePickerDischarge="filteredItensDatePickerDischarge"
      @lineClick="openAdmissionDetails"
      @open-upload-modal="openUploadModal"
      @refresh="refresh()"
      @refreshFirstPosition="refreshFirstPosition"
    ></DashboardPatient>
    <SpinnerLoader v-if="isLoading" />
    <div style="max-width: 1400px">
      <PaginationButtons
        v-if="patients && patients.length > 0"
        @update:page="onPageChange"
        :totalItems="total_items"
        :itemsPerPage="itemsPerPage"
        :currentPage="currentPage"
      ></PaginationButtons>
    </div>
    <UploadPatientDataModal v-if="isUploadModalOpen" @close="closeUploadModal" />
  </div>
</template>

<script>
import DashboardPatient from './DashboardPatient.vue';
import { OpenAPIJSON } from 'api/openapi';
import PaginationButtons from '../../components/admission/PaginationButtons.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import UploadPatientDataModal from '@/components/admission/UploadPatientDataModal.vue';
import { hospitalConfigStore } from '@/store/config';

export default {
  name: 'AdmissionPage',
  props: {},
  components: {
    DashboardPatient,
    PaginationButtons,
    SpinnerLoader,
    UploadPatientDataModal
  },

  data() {
    return {
      dataSummary: [],
      dataSummaryAdmission: [],
      dataSummaryDischarge: [],
      filteredState: null,
      datePickerdataDashboard: null,
      datePickerdataDashboardAdmission: null,
      datePickerdataDashboardDischarge: null,
      filterStateValue: null,
      scheduledDateValue: null,
      generalSearchValue: null,
      datePickerdata: null,
      patients: null,
      openApi: new OpenAPIJSON(),
      appointmentId: 1,
      total_items: 0,
      checks: null,
      currentPage: 1,
      itemsPerPage: 20,
      searchTerm: '',
      isLoading: false,
      isUploadModalOpen: false,
      summary: null,
      shouldHideContent: !hospitalConfigStore.getters.has_product_access,
      savedPayload: null,
      intervalId: null,

      selectedStatus: 'all',
      headers: [
        { key: '', label: '', width: 50, doneState: 'preadmit_state', id: 'id', icon: true },
        { key: '', label: '', width: 20, checkdata: 'checks', tooltip: 'checks', status: 'checks' },
        { key: 'preadmit_state', label: '', width: 200, selectedBox: 'preadmit_state', status: true },
        { key: 'scheduled_on', labelDate: 'Appointment date', width: 100, selectDate: true, date: true },
        { key: 'admitted_on', labelDate: 'Admission date', width: 100, selectDate: true, date: true },
        { key: 'discharged_on', labelDate: 'Discharge date', width: 100, selectDate: true, date: true },
        { key: 'patient.full_name', label: 'Patient Name', width: 200 },
        { key: 'patient.dob', label: 'Date of Birth', width: 150, dob: true, date: false },
        { key: 'insurance[0].insurer_name', label: 'Insurance Company', width: 150 }
        // {
        //   key: '',
        //   label: 'Insurer Policy',
        //   width: 150,
        //   checkdata_state: 'insurance[0].check_state',
        //   tooltip: 'check_state',
        //   status: 'check_state'
        // }
      ]
    };
  },

  mounted() {
    this.initializeComponent('first');
    this.intervalId = setInterval(() => {
      this.initializeComponent();
    }, 35000);
  },

  beforeUnmount() {
    document.title = 'Navigator';
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      
    }
    next();
  },

  computed: {
    refreshIcon() {
      const isNotNullOrUndefinedOrEmpty = value => value !== null && value !== undefined && value !== '';
      return (
        isNotNullOrUndefinedOrEmpty(this.filteredState) ||
        isNotNullOrUndefinedOrEmpty(this.datePickerdataDashboard) ||
        isNotNullOrUndefinedOrEmpty(this.searchTerm)
      );
    }
  },

  watch: {
    // searchTerm(newValue) {
    //   if (newValue === '') {
    //     this.getAppointmentDetails('');
    //   } else {
    //     this.getAppointmentDetails(newValue);
    //   }
    // }
  },

  methods: {
    async initializeComponent(newPage) {
      if (parseInt(this.$route.params.id) && newPage === 'first') {
        this.savedPayload = JSON.parse(sessionStorage.getItem('savedPayload'));
        await this.getDatesAppointmentsStorage();
        await this.getDatesAppointments();
      } else if (this.$route.params.id === '' && newPage === 'first') {
        await this.getAppointmentDetails('', true);
        await this.getDatesAppointments();
      } else if (parseInt(this.$route.params.id) && newPage === undefined) {
        this.savedPayload = JSON.parse(sessionStorage.getItem('savedPayload'));
        console.log(this.savedPayload);
        await this.getDatesAppointmentsStorage();
        await this.getDatesAppointments();
      } else if (sessionStorage.getItem('savedPayload')) {
        this.savedPayload = JSON.parse(sessionStorage.getItem('savedPayload'));
        await this.getDatesAppointmentsStorage();
        await this.getDatesAppointments();
      } else {
        await this.getAppointmentDetails('', true);
        await this.getDatesAppointments();
      }

      document.title = 'Access';
    },

    searchAppointment(newValue) {
      this.getAppointmentDetails(newValue);
      this.currentPage = 1;
    },

    openAdmissionDetails(item) {
      this.$router.push({ name: 'AdmissionDetails', params: { id: item.appointment_id } });
    },

    refreshFirstPosition(updatedDataValue) {
      this.patients = updatedDataValue;
    },

    refresh(value) {
      this.datePickerdata = value;
      this.datePickerdataDashboardAdmission = null;
      this.datePickerdataDashboardDischarge = null;
      this.filteredState = null;
      this.searchTerm = '';
      this.summary = [];
      this.getAppointmentDetails('', true);
    },

    async getAppointmentDetails(generalSearch, scheduledDateTrue) {
      if (generalSearch === undefined || generalSearch === null || generalSearch === '') {
        generalSearch = this.searchTerm;
      }

      this.isLoading = true;
      let today = null;
      let admission = null;
      let discharge = null;
      let filterState = this.filteredState;
      today = this.datePickerdataDashboard;
      admission = this.datePickerdataDashboardAdmission;
      discharge = this.datePickerdataDashboardDischarge;

      if (scheduledDateTrue) {
        today = new Date().toISOString().split('T')[0];
        admission = today;
        this.datePickerdata = new Date().toISOString().split('.')[0] + 'Z';
        this.datePickerdataDashboard = new Date().toISOString().split('T')[0];
      }

      try {
        const start_idx = (this.currentPage - 1) * this.itemsPerPage + 1;
        const end_idx = start_idx + this.itemsPerPage - 1;
        const preadmitStates = filterState ? [filterState] : [];

        this.savedPayload = {
          scheduled_on: today,
          admitted_on: admission,
          discharged_on: discharge,
          general: generalSearch,
          preadmit_states: preadmitStates,
          pagination: {
            start_idx: start_idx,
            end_idx: end_idx,
            page_size: null
          }
        };

        const response = await this.openApi.appointment_search(this.savedPayload);

        sessionStorage.setItem('savedPayload', JSON.stringify(this.savedPayload));
        sessionStorage.setItem('todayDate', this.datePickerdataDashboard);
        sessionStorage.setItem('admissionDate', this.datePickerdataDashboardAdmission);
        sessionStorage.setItem('dischargeDate', this.datePickerdataDashboardDischarge);
        sessionStorage.setItem('currentPage', this.currentPage);
        sessionStorage.setItem('searchTerm', this.searchTerm);

        this.savedPayload = JSON.parse(sessionStorage.getItem('savedPayload'));
        

        this.patients = response.items;

        // this.patients = response.items.filter(item => {
        //   return !item.insurance.some(ins => ins.insurer_id === 6 || ins.insurer_id === 5);
        // });

        this.total_items = response.total_items;
        this.summary = response.summary.pre_admission_state_count;

        if (generalSearch != '') {
          this.dataSummary = response.summary.unique_appointment_dates;
          this.dataSummaryAdmission = response.summary.unique_admission_dates;
          this.dataSummaryDischarge = response.summary.unique_discharge_dates;
        }
      } catch {
        this.isLoading = false;
      }

      if (this.total_items === 0 && this.searchTerm === '' && scheduledDateTrue) {
        this.searchTerm = '';
        this.filteredState = null;
        this.datePickerdataDashboard = null;
        this.datePickerdata = '';

        await this.getAppointmentDetails('', false);
      }
      this.isLoading = false;
    },

    async getDatesAppointments() {
      //this.isLoading = true;

      try {
        const response = await this.openApi.appointment_search({
          //pre_admission: true,

          general: '',
          pagination: {
            start_idx: 1,
            end_idx: 1,
            page_size: null
          }
        });
        this.dataSummary = response.summary.unique_appointment_dates;
        this.dataSummaryAdmission = response.summary.unique_admission_dates;
        this.dataSummaryDischarge = response.summary.unique_discharge_dates;
      } catch {
        // this.isLoading = false;
      }

      //this.isLoading = false;
    },

    async getDatesAppointmentsStorage() {
      // this.isLoading = true;

      try {
        const response = await this.openApi.appointment_search(this.savedPayload);
        this.patients = response.items;

        this.total_items = response.total_items;
        this.summary = response.summary.pre_admission_state_count;
        this.dataSummary = response.summary.unique_appointment_dates;
        this.dataSummaryAdmission = response.summary.unique_admission_dates;
        this.dataSummaryDischarge = response.summary.unique_discharge_dates;

        this.datePickerdata = sessionStorage.getItem('todayDate');
        let admissionDate = sessionStorage.getItem('admissionDate');
        let dischargeDate = sessionStorage.getItem('dischargeDate');
        let currentPage = sessionStorage.getItem('currentPage');
        let searchTerm = sessionStorage.getItem('searchTerm');

        this.datePickerdataDashboardAdmission = admissionDate === 'null' ? null : admissionDate;
        this.datePickerdataDashboardDischarge = dischargeDate === 'null' ? null : dischargeDate;
        this.searchTerm = searchTerm === 'null' ? '' : searchTerm;

        this.currentPage = currentPage === 'null' || currentPage === '' ? 1 : parseInt(currentPage, 10);
      } catch {
        //this.isLoading = false;
      }

      // this.isLoading = false;
    },

    filteredItens(value) {
      this.filteredState = value;
      this.currentPage = 1;
      this.getAppointmentDetails();
    },

    filteredItensDatePicker(value) {
      this.currentPage = 1;
      this.datePickerdataDashboard = value;
      this.datePickerdataDashboardAdmission = null;
      this.datePickerdataDashboardDischarge = null;
      this.getAppointmentDetails();
    },
    filteredItensDatePickerAdmission(value) {
      this.currentPage = 1;
      this.datePickerdataDashboardAdmission = value;
      this.datePickerdataDashboardDischarge = null;
      this.datePickerdataDashboard = null;
      this.getAppointmentDetails();
    },
    filteredItensDatePickerDischarge(value) {
      this.currentPage = 1;
      this.datePickerdataDashboard = null;
      this.datePickerdataDashboardDischarge = value;
      this.datePickerdataDashboardAdmission = null;
      this.getAppointmentDetails();
    },

    async onPageChange(newPage) {
      this.currentPage = newPage;
      this.getAppointmentDetails();
    },

    openUploadModal() {
      this.isUploadModalOpen = true;
    },
    closeUploadModal() {
      this.isUploadModalOpen = false;
    },
    clearSearch() {
      this.searchTerm = '';
      this.getAppointmentDetails(this.searchTerm);
    }
  }
};
</script>

<style scoped>
.search-input-field_ {
  width: 100%;
  height: 45px;
  outline: none;
  background: #f9f9f9;
  padding-right: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

.search-icon-wrapper {
  display: flex;
  align-items: center;
  background: #00a7e1;
  padding: 0 13px;
  border-radius: 0 4px 4px 0;
  height: 45px;
}

.search-icon {
  color: #fff;
  cursor: pointer;
}

.clear-search-icon {
  position: absolute;
  right: 55px;
  top: 50%;
  transform: translateY(-50%);
  color: #050505;
  cursor: pointer;
}
</style>

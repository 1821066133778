<script setup>
import { useForm } from 'vee-validate';
import { object, string, date, boolean, array } from 'yup';

import MembershipDetails from './sections/garda/MembershipDetails.vue';
import InjurySection from './sections/garda/InjurySection.vue';
import RequestForPrivateCareSection from './sections/garda/RequestForPrivateCareSection.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  }
});

const { values, setValues, defineInputBinds } = useForm({
  validationSchema: object({
    policyNo: string(),
    patientName: string().required(),
    patientAddress: array().of(string()).required(),
    birthDate: date().required().min(new Date('1900-01-01')).max(new Date()),
    telephone: string().phone().required(),

    roadTrafficAccident: boolean(),
    onDuty: boolean(),
    sporting: boolean(),
    atWork: boolean(),
    roadTrafficAccidentDate: date(),
    onDutyDate: date(),
    sportingDate: date(),
    atWorkDate: date(),

    privatePatient: boolean(),
    electedToBePrivatePatientDate: date(),
    admissionFormSigned: boolean()
  }),
  initialValues: {
    policyNo: props.initialData?.policyNo ?? undefined,
    patientName: props.initialData?.patientName ?? undefined,
    patientAddress: props.initialData?.patientAddress ?? undefined,
    birthDate: props.initialData?.birthDate ?? undefined,
    telephone: props.initialData?.telephone ?? undefined,

    roadTrafficAccident: props.initialData?.roadTrafficAccident ?? undefined,
    onDuty: props.initialData?.onDuty ?? undefined,
    sporting: props.initialData?.sporting ?? undefined,
    atWork: props.initialData?.atWork ?? undefined,
    roadTrafficAccidentDate: props.initialData?.roadTrafficAccidentDate ?? undefined,
    onDutyDate: props.initialData?.onDutyDate ?? undefined,
    sportingDate: props.initialData?.sportingDate ?? undefined,
    atWorkDate: props.initialData?.atWorkDate ?? undefined,

    privatePatient: props.initialData?.privatePatient ?? undefined,
    electedToBePrivatePatientDate: props.initialData?.electedToBePrivatePatientDate ?? undefined,
    admissionFormSigned: props.initialData?.admissionFormSigned ?? undefined
  }
});

const roadTrafficAccident = defineInputBinds('roadTrafficAccident');
const injuryOnDuty = defineInputBinds('onDuty');
const gaaSportingInjury = defineInputBinds('sporting');
const injuryInSchoolOrWork = defineInputBinds('atWork');

defineExpose({ values, setValues });
</script>

<template>
  <MembershipDetails id="garda-membership-details" :edit-mode="editMode" :warnings="warnings" />
  <InjurySection
    id="garda-injury-section"
    :edit-mode="editMode"
    :warnings="warnings"
    :road-traffic-accident="roadTrafficAccident.value"
    :injury-on-duty="injuryOnDuty.value"
    :gaa-sporting-injury="gaaSportingInjury.value"
    :injury-in-school-or-work="injuryInSchoolOrWork.value"
  />
  <RequestForPrivateCareSection id="garda-private-care" :edit-mode="editMode" :warnings="warnings" />
</template>

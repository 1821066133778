export function getClaimSectionIds(claimFormType) {
  switch (claimFormType.toLowerCase()) {
    case 'esb':
    case 'e_s_b':
      return [
        { name: '(1) Patient Details', id: 'esb_patient_details' },
        { name: '(2) Hospital Treatment', id: 'section_hospital_treatment-hdr' },
        { name: '(3) Consultant & Medical Details', id: 'section_medical_details-hdr' },
        { name: '(4) Treatments', id: 'section_treatments-hdr' },
        { name: '(5) MRI Treatments', id: 'section_mri_treatments-hdr' }
      ];
    case 'garda':
      return [
        { name: '(1) Patient Details', id: 'garda_patient_details' },
        { name: '(4) Hospital Details', id: 'section4_hospital-hdr' },
        { name: '(4) Hospital Details - Accommodation', id: 'section4_beds-hdr' },
        { name: '(6) Medical Investigations', id: 'section_medical_investigations-hdr' },
        { name: '(6) Diagnosis', id: 'section_diagnosis-hdr' },
        { name: '(6) Treatments', id: 'section_treatments-hdr' },
        { name: '(6) Treatments - Consultant Services', id: 'section_consultant_services-hdr' },
        { name: '(6) Treatments - Transfers', id: 'section_transfers-hdr' },
        { name: '(7) Discharge Status', id: 'section_discharge-hdr' }
      ];
    case 'irish_life_hospital':
      return [
        { name: '(1) Patient Details', id: 'irish_life_hospital_patient_details' },
        { name: '(2) Admission Details', id: 'section_admission_details-hdr' },
        { name: '(3) Admitting Diagnosis', id: 'section_admitting_diagnosis-hdr' },
        { name: '(4) Surgical Procedures', id: 'section_surgical_procedures-hdr' },
        { name: '(5) Non-Surgical Treatments', id: 'section_non_surgical_procedures-hdr' },
        { name: '(6) Misceallany', id: 'section_treatment_misc-hdr' },
        { name: '(7) Discharge', id: 'section_discharge-hdr' }
      ];
    case 'irish_life_hospital2022':
      return [
        { name: '(1) Patient Details', id: 'irish_life_hospital2022_patient_details' },
        { name: '(2) Admission Details', id: 'section_admission_details-hdr' },
        { name: '(3) Surgical Treatments', id: 'section_surgical_procedures-hdr' },
        { name: '(4) IV Therapy', id: 'section_iv_therapy-hdr' },
        { name: '(5) Non-Surgical Treatments / ICU / Other', id: 'section_non_surgical_procedures-hdr' },
        { name: '(6) Discharge', id: 'section_discharge-hdr' }
      ];
    case 'laya_inpatient':
      return [
        { name: '(1) Patient Details', id: 'laya_patient_details' },
        { name: '(7) Hospital Treatment - Accommodation', id: 'section7_accommodation-hdr' },
        { name: '(8) Consultant & Medical Details - Symptoms', id: 'section8_symptoms-hdr' },
        { name: '(8) Consultant & Medical Details - Diagnosis', id: 'section8_diagnosis-hdr' },
        { name: '(8) Consultant & Medical Details - Procedures/Anaesthesia', id: 'section8_procedures-hdr' },
        { name: '(8) Consultant & Medical Details - Medical Management', id: 'section8_medical_management-hdr' },
        { name: '(8) Consultant & Medical Details - Pharmacotherapy', id: 'section8_pharmacotherapy-hdr' },
        {
          name: '(8) Consultant & Medical Details - Details of Hospital Stay',
          id: 'section8_details_of_hospital_stay-hdr'
        },
        { name: '(9) In-Patient CT/MRI', id: 'section9_mri_ct-hdr' }
      ];
    case 'prison_officers':
      return [
        { name: '(1) Patient Details', id: 'prison_officers_patient_details' },
        { name: '(2) Medical History', id: 'section_medical_history-hdr' },
        { name: '(3) Treatments', id: 'section_treatments-hdr' }
      ];
    case 'vhi_hospital':
      return [
        { name: '(1) Patient Details', id: 'vhi_patient_details' },
        { name: '(6) Medical History', id: 'section6_medical_history-hdr' },
        { name: '(7) Medical Investigations', id: 'section7_labs-hdr' },
        { name: '(8) Diagnosis', id: 'section8_diagnosis-hdr' },
        { name: '(9) Treatment Section (Procedures)', id: 'section9_procedures-hdr' },
        { name: '(9) Treatment Section - Medical Attendance (IV)', id: 'section9_medical_attendance-hdr' },
        { name: '(9) Treatment Section - General', id: 'section9_general-hdr' },
        { name: '(10) Discharge Status', id: 'section10_discharge-hdr' }
      ];
    case 'vhi_c_t':
      return [
        { name: '(1) Patient Details', id: 'vhi_c_t_patient_details' },
        { name: '(6) Medical History', id: 'section6_medical_history-hdr' },
        { name: '(7) CT Details', id: 'section7_labs-hdr' }
      ];
    case 'vhi_mri':
      return [
        { name: '(1) Patient Details', id: 'vhi_mri_patient_details' },
        { name: '(6) Medical History', id: 'section6_medical_history-hdr' },
        { name: '(7) MRI Details', id: 'section7_labs-hdr' }
      ];
    case 'vhi_oncology':
      return [
        { name: '(1) Patient Details', id: 'vhi_oncology_patient_details' },
        { name: '(6) Medical History', id: 'section6_medical_history-hdr' },
        { name: '(7) Medical Investigations', id: 'section7_labs-hdr' },
        { name: '(8) Diagnosis', id: 'section8_diagnosis-hdr' },
        { name: '(9) Details of Treatment/Cancer Chemotherapy Drugs', id: 'section9_treatment-details-hdr' },
        { name: '(10) Other Services', id: 'section10_other_services-hdr' },
        { name: '(11) Discharge Status', id: 'section11_discharge-hdr' }
      ];
    case 'vhi_surgical':
      return [
        { name: '(1) Patient Details', id: 'vhi_surgical_patient_details' },
        { name: '(5) Medical History', id: 'section5_medical_history-hdr' },
        { name: '(6) Diagnosis', id: 'section6_diagnosis-hdr' },
        { name: '(7) Treatment Section', id: 'section7_treatment-hdr' }
      ];
    default:
      return [];
  }
}

export function getClaimContainerId(claimFormType) {
  switch (claimFormType.toLowerCase()) {
    case 'esb':
    case 'e_s_b':
      return 'claimform_esb';
    case 'garda':
      return 'claimform_garda';
    case 'irish_life_hospital':
      return 'claimform_irish_life';
    case 'irish_life_hospital2022':
      return 'claimform_irish_life_hospital_2022';
    case 'laya_inpatient':
      return 'claimform_laya_inpatient';
    case 'prison_officers':
      return 'claimform_prison_officers';
    case 'vhi_hospital':
      return 'claimform_vhi_hospital';
    case 'vhi_c_t':
      return 'claimform_vhi_ct';
    case 'vhi_mri':
      return 'claimform_vhi_mri';
    case 'vhi_oncology':
      return 'claimform_vhi_oncology';
    case 'vhi_surgical':
      return 'claimform_vhi_surgical';
    default:
      return [];
  }
}

export function getAdmissionClaimSectionIds(claimFormType) {
  switch (claimFormType.toLowerCase()) {
    case 'esb':
    case 'e_s_b':
      return [
        { name: '(1) Membership Details', id: 'esb-membership-details' },
        { name: '(2) Injury Section', id: 'esb-injury-section' },
        { name: '(3) Signature', id: 'signature-pad-element-title' }
      ];
    case 'garda':
      return [
        { name: '(1) Membership Details', id: 'garda-membership-details' },
        { name: '(2) Injury Section', id: 'garda-injury-section' },
        { name: '(3) Request for Private Care', id: 'garda-private-care' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'irish-life':
    case 'irish_life_hospital':
      return [
        { name: '(1) Membership Details', id: 'irish-life-membership-details' },
        { name: '(2) Illness History', id: 'irish-life-illness-history' },
        { name: '(3) Accident Details', id: 'irish-life-accident-details' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'irish_life_hospital2022':
      return [
        { name: '(1) Membership Details', id: 'irish-life-membership-details' },
        { name: '(2) Illness History', id: 'irish-life-illness-history' },
        { name: '(3) Accident Details', id: 'irish-life-accident-details' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'laya':
    case 'laya_inpatient':
      return [
        { name: '(1) Policy Details', id: 'laya-policy-details' },
        { name: '(2) Hospital Details', id: 'laya-hospital-details' },
        { name: '(3) History of Illness', id: 'laya-illness-history' },
        { name: '(4) Referral Details', id: 'laya-referral-details' },
        { name: '(5) Accident Details', id: 'laya-accident-details' },
        { name: '(6) Signature', id: 'signature-pad-element-title' }
      ];
    case 'prison-officers':
    case 'prison_officers':
    case 'pomas':
      return [
        { name: '(1) Membership Details', id: 'pomas-membership-details' },
        { name: '(2) Section A', id: 'pomas-section-a' },
        { name: '(3) Section B', id: 'pomas-section-b' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'vhi-hospital':
    case 'vhi_hospital':
      return [
        { name: '(1) Policy Details', id: 'vhi-hospital-policy-details' },
        { name: '(2) History of Illness', id: 'vhi-hospital-illness-history' },
        { name: '(3) Injury Details', id: 'vhi-hospital-injury-section' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'vhi-ct':
    case 'vhi_c_t':
      return [
        { name: '(1) Policy Details', id: 'vhi-ct-policy-details' },
        { name: '(2) Illness History', id: 'vhi-ct-illness-history-ct' },
        { name: '(3) Injury Section', id: 'vhi-ct-injury-section' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'vhi-mri':
    case 'vhi_mri':
      return [
        { name: '(1) Policy Details', id: 'vhi-mri-policy-details' },
        { name: '(2) Illness History', id: 'vhi-mri-illness-history' },
        { name: '(3) Injury Section', id: 'vhi-mri-injury-section' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'vhi-oncology':
    case 'vhi_oncology':
      return [
        { name: '(1) Policy Details', id: 'vhi-oncology-policy-details' },
        { name: '(2) Illness History', id: 'vhi-oncology-illness-history' },
        { name: '(3) Injury Section', id: 'vhi-oncology-injury-section' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    case 'vhi-surgical':
    case 'vhi_surgical':
      return [
        { name: '(1) Policy Details', id: 'vhi-surgical-policy-details-surgical' },
        { name: '(2) History of Illness ', id: 'vhi-surgical-illness-history-surgical' },
        { name: '(3) Injury Details', id: 'vhi-surgical-injury-section' },
        { name: '(4) Signature', id: 'signature-pad-element-title' }
      ];
    default:
      return [];
  }
}

export function modifyLayaFormSections(treatmentResultOfAccident, similarIllness) {
  if (treatmentResultOfAccident && similarIllness) {
    return [
      { name: '(1) Policy Details', id: 'laya-policy-details' },
      { name: '(2) Hospital Details', id: 'laya-hospital-details' },
      { name: '(3) History of Illness', id: 'laya-illness-history' },
      { name: '(4) Referral Details', id: 'laya-referral-details' },
      { name: '(5) Accident Details', id: 'laya-accident-details' },
      { name: '(6) Declaration', id: 'laya-data-protection' },
      { name: '(6) Signature', id: 'signature-pad-element-title' }
    ];
  } else if (treatmentResultOfAccident && !similarIllness) {
    return [
      { name: '(1) Policy Details', id: 'laya-policy-details' },
      { name: '(2) Hospital Details', id: 'laya-hospital-details' },
      { name: '(3) History of Illness', id: 'laya-illness-history' },
      { name: '(4) Referral Details', id: 'laya-referral-details' },
      { name: '(4) Accident Details', id: 'laya-accident-details' },
      { name: '(6) Declaration', id: 'laya-data-protection' },
      { name: '(6) Signature', id: 'signature-pad-element-title' }
    ];
  } else if (!treatmentResultOfAccident && similarIllness) {
    return [
      { name: '(1) Policy Details', id: 'laya-policy-details' },
      { name: '(2) Hospital Details', id: 'laya-hospital-details' },
      { name: '(3) History of Illness', id: 'laya-illness-history' },
      { name: '(4) Referral Details', id: 'laya-referral-details' },
      { name: '(6) Declaration', id: 'laya-data-protection' },
      { name: '(6) Signature', id: 'signature-pad-element-title' }
    ];
  } else if (!treatmentResultOfAccident && !similarIllness) {
    return [
      { name: '(1) Policy Details', id: 'laya-policy-details' },
      { name: '(2) Hospital Details', id: 'laya-hospital-details' },
      { name: '(3) History of Illness', id: 'laya-illness-history' },
      { name: '(4) Referral Details', id: 'laya-referral-details' },
      { name: '(6) Signature', id: 'signature-pad-element-title' }
    ];
  }
}

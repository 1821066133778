<script setup lang="ts">
import {
  BaseInput
} from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  }
});

const sectionHasWarnings = (sectionId) => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle 
      id="pomas-section-a-title" 
      title="Section A" 
    />
    <div class="mt-6">
      <label class="block text-base sm:text-lg font-medium mb-1 sm:mb-3">1. Maintenance:</label>
      <label class="block text-base font-medium">General Hospital</label>
      <div class="flex flex-wrap gap-3">
        <BaseInput id="general-hospital-member" name="generalHospitalMember" label="Member" :edit-mode="editMode" :is-warning="sectionHasWarnings('general-hospital-member')" />
        <BaseInput id="general-hospital-spouse" name="generalHospitalSpouse" label="Spouse" :edit-mode="editMode" :is-warning="sectionHasWarnings('general-hospital-spouse')" />
        <BaseInput id="general-hospital-children" name="generalHospitalChildren" label="Children" :edit-mode="editMode" :is-warning="sectionHasWarnings('general-hospital-children')" />
        <BaseInput id="general-hospital-no-bills" name="generalHospitalNoBills" label="No of Bills" :edit-mode="editMode" :is-warning="sectionHasWarnings('general-hospital-no-bills')" />
      </div>
      <label class="block text-base font-medium mt-3 sm:mt-6">Professional Fees</label>
      <div class="flex flex-wrap gap-3">
        <BaseInput id="professional-fees-member" name="generalHospitalProfessionalFeesMember" label="Member" :edit-mode="editMode" :is-warning="sectionHasWarnings('professional-fees-member')" />
        <BaseInput id="professional-fees-spouse" name="generalHospitalProfessionalFeesSpouse" label="Spouse" :edit-mode="editMode" :is-warning="sectionHasWarnings('professional-fees-spouse')" />
        <BaseInput id="professional-fees-children" name="generalHospitalProfessionalFeesChildren" label="Children" :edit-mode="editMode" :is-warning="sectionHasWarnings('professional-fees-children')" />
        <BaseInput id="professional-fees-no-bills" name="generalHospitalProfessionalFeesNoBills" label="No of Bills" :edit-mode="editMode" :is-warning="sectionHasWarnings('professional-fees-no-bills')" />
      </div>
    </div>

    <div class="mt-6 sm:mt-12">
      <label class="block text-base sm:text-lg font-medium mb-1 sm:mb-3">2. Maintenance:</label>
      <label class="block text-base font-medium">Maternity</label>
      <div class="flex flex-wrap gap-3">
        <BaseInput id="maternity-member" name="maternityMember" label="Member" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-member')" />
        <BaseInput id="maternity-spouse" name="maternitySpouse" label="Spouse" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-spouse')" />
        <BaseInput id="maternity-children" name="maternityChildren" label="Children" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-children')" />
        <BaseInput id="maternity-no-bills" name="maternityNoBills" label="No of Bills" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-no-bills')" />
      </div>
      <label class="block text-base font-medium mt-5">Professional Fees</label>
      <div class="flex flex-wrap gap-3">
        <BaseInput id="maternity-professional-fees-member" name="maternityProfessionalFeesMember" label="Member" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-professional-fees-member')" />
        <BaseInput id="maternity-professional-fees-spouse" name="maternityProfessionalFeesSpouse" label="Spouse" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-professional-fees-spouse')" />
        <BaseInput id="maternity-professional-fees-children" name="maternityProfessionalFeesChildren" label="Children" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-professional-fees-children')" />
        <BaseInput id="maternity-professional-fees-no-bills" name="maternityProfessionalFeesNoBills" label="No of Bills" :edit-mode="editMode" :is-warning="sectionHasWarnings('maternity-professional-fees-no-bills')" />
      </div>
    </div>

    <div class="mt-6 sm:mt-12">
      <label class="block text-base sm:text-lg font-medium mb-1 sm:mb-3">3. Maintenance:</label>
      <label class="block text-base font-medium">Dental</label>
      <div class="flex flex-wrap gap-3">
        <BaseInput id="dental-member" name="dentalMember" label="Member" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-member')" />
        <BaseInput id="dental-spouse" name="dentalSpouse" label="Spouse" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-spouse')" />
        <BaseInput id="dental-children" name="dentalChildren" label="Children" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-children')" />
        <BaseInput id="dental-no-bills" name="dentalNoBills" label="No of Bills" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-no-bills')" />
      </div>
      <label class="block text-base font-medium mt-5">Professional Fees</label>
      <div class="flex flex-wrap gap-3">
        <BaseInput id="dental-professional-fees-member" name="dentalProfessionalFeesMember" label="Member" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-professional-fees-member')" />
        <BaseInput id="dental-professional-fees-spouse" name="dentalProfessionalFeesSpouse" label="Spouse" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-professional-fees-spouse')" />
        <BaseInput id="dental-professional-fees-children" name="dentalProfessionalFeesChildren" label="Children" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-professional-fees-children')" />
        <BaseInput id="dental-professional-fees-no-bills" name="dentalProfessionalFeesNoBills" label="No of Bills" :edit-mode="editMode" :is-warning="sectionHasWarnings('dental-professional-fees-no-bills')" />
      </div>
    </div>

    <div class="mt-6 sm:mt-12">
      <label class="block text-lg font-medium mt-8 mb-3">Totals:</label>
      <div class="flex flex-wrap gap-3">
        <BaseInput id="total-member" name="memberTotal" label="Member" :edit-mode="editMode" :is-warning="sectionHasWarnings('total-member')" />
        <BaseInput id="total-spouse" name="spouseTotal" label="Spouse" :edit-mode="editMode" :is-warning="sectionHasWarnings('total-spouse')" />
        <BaseInput id="total-children" name="childrenTotal" label="Children" :edit-mode="editMode" :is-warning="sectionHasWarnings('total-children')" />
        <BaseInput id="total-no-bills" name="noOfBillsTotal" label="No of Bills" :edit-mode="editMode" :is-warning="sectionHasWarnings('total-no-bills')" />
      </div>
    </div>
  </div>
</template>

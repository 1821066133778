<script setup>
import { useForm } from 'vee-validate';
import { object, string, boolean, array } from 'yup';

import MembershipDetails from './sections/pomas/MembershipDetails.vue';
import SectionA from './sections/pomas/SectionA.vue';
import SectionB from './sections/pomas/SectionB.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  }
});

const { values, setValues } = useForm({
  validationSchema: object({
    patientName: string().required(),
    patientAddress: array().of(string()).required(),
    patientPartner: string(),
    patientChildren: string(),

    generalHospitalMember: string(),
    generalHospitalSpouse: string(),
    generalHospitalChildren: string(),
    generalHospitalNoBills: string(),
    generalHospitalProfessionalFeesMember: string(),
    generalHospitalProfessionalFeesSpouse: string(),
    generalHospitalProfessionalFeesChildren: string(),
    generalHospitalProfessionalFeesNoBills: string(),
    maternityMember: string(),
    maternitySpouse: string(),
    maternityChildren: string(),
    maternityNoBills: string(),
    maternityProfessionalFeesMember: string(),
    maternityProfessionalFeesSpouse: string(),
    maternityProfessionalFeesChildren: string(),
    maternityProfessionalFeesNoBills: string(),
    dentalMember: string(),
    dentalSpouse: string(),
    dentalChildren: string(),
    dentalNoBills: string(),
    dentalProfessionalFeesMember: string(),
    dentalProfessionalFeesSpouse: string(),
    dentalProfessionalFeesChildren: string(),
    dentalProfessionalFeesNoBills: string(),
    memberTotal: string(),
    spouseTotal: string(),
    childrenTotal: string(),
    noOfBillsTotal: string(),

    roadTrafficAccident: boolean(),
    causedByThirdParty: boolean(),
    onDuty: boolean(),
    fromWar: boolean()
  }),
  initialValues: {
    patientName: props.initialData?.patientName ?? undefined,
    patientAddress: props.initialData?.patientAddress ?? undefined,
    patientPartner: props.initialData?.patientPartner ?? undefined,
    patientChildren: props.initialData?.patientChildren ?? undefined,

    generalHospitalMember: props.initialData?.generalHospitalMember ?? undefined,
    generalHospitalSpouse: props.initialData?.generalHospitalSpouse ?? undefined,
    generalHospitalChildren: props.initialData?.generalHospitalChildren ?? undefined,
    generalHospitalNoBills: props.initialData?.generalHospitalNoBills ?? undefined,
    generalHospitalProfessionalFeesMember: props.initialData?.generalHospitalProfessionalFeesMember ?? undefined,
    generalHospitalProfessionalFeesSpouse: props.initialData?.generalHospitalProfessionalFeesSpouse ?? undefined,
    generalHospitalProfessionalFeesChildren: props.initialData?.generalHospitalProfessionalFeesChildren ?? undefined,
    generalHospitalProfessionalFeesNoBills: props.initialData?.generalHospitalProfessionalFeesNoBills ?? undefined,
    maternityMember: props.initialData?.maternityMember ?? undefined,
    maternitySpouse: props.initialData?.maternitySpouse ?? undefined,
    maternityChildren: props.initialData?.maternityChildren ?? undefined,
    maternityNoBills: props.initialData?.maternityNoBills ?? undefined,
    maternityProfessionalFeesMember: props.initialData?.maternityProfessionalFeesMember ?? undefined,
    maternityProfessionalFeesSpouse: props.initialData?.maternityProfessionalFeesSpouse ?? undefined,
    maternityProfessionalFeesChildren: props.initialData?.maternityProfessionalFeesChildren ?? undefined,
    maternityProfessionalFeesNoBills: props.initialData?.maternityProfessionalFeesNoBills ?? undefined,
    dentalMember: props.initialData?.dentalMember ?? undefined,
    dentalSpouse: props.initialData?.dentalSpouse ?? undefined,
    dentalChildren: props.initialData?.dentalChildren ?? undefined,
    dentalNoBills: props.initialData?.dentalNoBills ?? undefined,
    dentalProfessionalFeesMember: props.initialData?.dentalProfessionalFeesMember ?? undefined,
    dentalProfessionalFeesSpouse: props.initialData?.dentalProfessionalFeesSpouse ?? undefined,
    dentalProfessionalFeesChildren: props.initialData?.dentalProfessionalFeesChildren ?? undefined,
    dentalProfessionalFeesNoBills: props.initialData?.dentalProfessionalFeesNoBills ?? undefined,
    memberTotal: props.initialData?.memberTotal ?? undefined,
    spouseTotal: props.initialData?.spouseTotal ?? undefined,
    childrenTotal: props.initialData?.childrenTotal ?? undefined,
    noOfBillsTotal: props.initialData?.noOfBillsTotal ?? undefined,

    roadTrafficAccident: props.initialData?.roadTrafficAccident ?? undefined,
    causedByThirdParty: props.initialData?.causedByThirdParty ?? undefined,
    onDuty: props.initialData?.onDuty ?? undefined,
    fromWar: props.initialData?.fromWar ?? undefined
  }
});

defineExpose({ values, setValues });
</script>

<template>
  <MembershipDetails id="pomas-membership-details" :edit-mode="editMode" :warnings="warnings" />
  <SectionA id="pomas-section-a" :edit-mode="editMode" :warnings="warnings" />
  <SectionB id="pomas-section-b" :edit-mode="editMode" :warnings="warnings" />
</template>

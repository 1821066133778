<template>
  <div class="insurance-dropdown">
    <Typeahead
      v-model="selectedInsurerCompany"
      :items="insurers"
      :placeholder="this.placeholder"
      :min-input-length="this.min_input"
      :item-projection="show_suggestion"
      :item-value="show_name"
      @select-item="selectItem"
      @update:modelValue="check_if_matching"
      :fullW="true"
    />
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';

// {"claim_state":["sent_to_insurer","part_paid"],"consultant":{"type":"person_id","id":4}}

/**
 * Typeahead for lookup of insurance companies that are registered to the hospital.
 *
 * Emits a data structure result though the event update:modelValue:
 *
 *    {
 *       "insurer": {
 *         "id": 1,
 *         "ro": {
 *           "insurer_id": 1,
 *           "insurer_name": "The Good Insurance Company",
 *           "contact": {
 *             "postal_address": [
 *               "6531 Insurance Street",
 *               "Dundrum",
 *               "Dublin",
 *               "Ireland"
 *             ],
 *             "telephone_landline": null,
 *             "telephone_mobile": null,
 *             "telephone_fax": null,
 *             "email": null
 *           }
 *         },
 *         "type": "id"
 *       },
 *       "insurer_id": 1,
 *       "insurer_name": "The Good Insurance Company",
 *       "aliases": [
 *         "Good Ins Alias 1",
 *         "Good Ins Alias 2"
 *       ]
 *     }
 */
export default {
  name: 'InsuranceCompany',
  components: {
    Typeahead
  },
  props: {
    modelValue: {},
    placeholder: { require: false, default: 'Search for Insurer' },
    min_input: { require: false, default: 1 }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      insurers: [],
      selectedInsurerCompany: '',
      api: new OpenAPIJSON()
    };
  },
  watch: {
    selectedInsurerCompany: async function (new_filter) {
      let search_term = new_filter.trim();
      let results = { items: [] };
      if (search_term.length > 0) {
        results = await this.api.searchInsurances(new_filter);
      }
      if (results.items.length > 0) {
        this.insurers = results.items;
      } else {
        this.insurers = [];
      }
    }
  },
  methods: {
    show_suggestion(item) {
      if (item === '') {
        return '';
      }
      return item.insurer_name;
    },
    show_name(item) {
      this.$emit('update:modelValue', item);
      this.selectedInsurerCompany = item.insurer_name;
      return item.insurer_name;
    },
    selectItem(item) {
      this.$emit('update:modelValue', item);
      this.selectedInsurerCompany = item.insurer_name;
    },
    check_if_matching(item) {
      //console.log("ProcedureCodeTypeahead: check_if_matching item=", item);
      if (item.length === 0) {
        this.$emit('update:modelValue', {});
      }
    },
    mounted() {
      if (this.modelValue) {
        this.selectedInsurerCompany = this.modelValue;
      }
    }
  }
};
</script>

<style scoped>
.insurance-dropdown {
  display: inline-block;
}
</style>
